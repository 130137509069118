import React from 'react';
import VirtualPiano from './components/VirtualPiano';

function App() {
  return (
    <div className="App">
      <VirtualPiano />
    </div>
  );
}

export default App;