import './VirtualPiano.css';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Piano } from 'lucide-react';
import { Helmet } from 'react-helmet';

const noteFrequencies = {
  'C2': 65.41, 'C#2': 69.30, 'D2': 73.42, 'D#2': 77.78, 'E2': 82.41, 'F2': 87.31, 'F#2': 92.50, 'G2': 98.00, 'G#2': 103.83, 'A2': 110.00, 'A#2': 116.54, 'B2': 123.47,
  'C3': 130.81, 'C#3': 138.59, 'D3': 146.83, 'D#3': 155.56, 'E3': 164.81, 'F3': 174.61, 'F#3': 185.00, 'G3': 196.00, 'G#3': 207.65, 'A3': 220.00, 'A#3': 233.08, 'B3': 246.94,
  'C4': 261.63, 'C#4': 277.18, 'D4': 293.66, 'D#4': 311.13, 'E4': 329.63, 'F4': 349.23, 'F#4': 369.99, 'G4': 392.00, 'G#4': 415.30, 'A4': 440.00, 'A#4': 466.16, 'B4': 493.88,
  'C5': 523.25, 'C#5': 554.37, 'D5': 587.33, 'D#5': 622.25, 'E5': 659.25, 'F5': 698.46, 'F#5': 739.99, 'G5': 783.99, 'G#5': 830.61, 'A5': 880.00, 'A#5': 932.33, 'B5': 987.77,
  'C6': 1046.50, 'C#6': 1108.73, 'D6': 1174.66, 'D#6': 1244.51, 'E6': 1318.51, 'F6': 1396.91, 'F#6': 1479.98, 'G6': 1567.98, 'G#6': 1661.22, 'A6': 1760.00, 'A#6': 1864.66, 'B6': 1975.53,
  'C7': 2093.00
};

const keyboardToNote = {
  // First octave (C2 to B2)
  'z': 'C2', 's': 'C#2', 'x': 'D2', 'd': 'D#2', 'c': 'E2', 
  'v': 'F2', 'g': 'F#2', 'b': 'G2', 'h': 'G#2', 'n': 'A2', 'j': 'A#2', 'm': 'B2',

  // Second octave (C3 to B3)
  'q': 'C3', '2': 'C#3', 'w': 'D3', '3': 'D#3', 'e': 'E3',
  'r': 'F3', '5': 'F#3', 't': 'G3', '6': 'G#3', 'y': 'A3', '7': 'A#3', 'u': 'B3',

  // Third octave (C4 to B4)
  'i': 'C4', '9': 'C#4', 'o': 'D4', '0': 'D#4', 'p': 'E4',
  '[': 'F4', '=': 'F#4', ']': 'G4', '\\': 'G#4', 'a': 'A4', 'k': 'A#4', 'l': 'B4',

  // Fourth octave (partial, C5 to F5)
  ';': 'C5', "'": 'C#5', ',': 'D5', '.': 'D#5', '/': 'E5', '-': 'F5'
};

// Helper functions
const getSemitoneDistance = (note1, note2) => {
  const freq1 = noteFrequencies[note1];
  const freq2 = noteFrequencies[note2];
  return Math.round(12 * Math.log2(freq2 / freq1));
};

// Custom hooks
const useAudioContext = () => {
  const [audioContext, setAudioContext] = useState(null);

  useEffect(() => {
    const context = new (window.AudioContext || window.webkitAudioContext)();
    setAudioContext(context);
    return () => context.close();
  }, []);

  return audioContext;
};

const useSampleLoading = (audioContext, setCSamples) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!audioContext) return;

    const loadSamples = async () => {
      try {
        const newSamples = {};
        for (let i = 0; i <= 7; i++) {
          const response = await fetch(`/Samples/C${i}.wav`);
          if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
          const arrayBuffer = await response.arrayBuffer();
          newSamples[`C${i}`] = await audioContext.decodeAudioData(arrayBuffer);
        }
        setCSamples(newSamples);
        setIsLoading(false);
      } catch (error) {
        console.error('Failed to load samples:', error);
        setIsLoading(false);
      }
    };

    loadSamples();
  }, [audioContext, setCSamples]);

  return isLoading;
};

const VirtualPiano = () => {
  const [playingNotes, setPlayingNotes] = useState({});
  const [cSamples, setCSamples] = useState({});
  const [presssedNotes, setPressedNotes] = useState([]);
  const [currentChord, setCurrentChord] = useState('None');
  const [isDragging, setIsDragging] = useState(false);
  const audioContext = useAudioContext();
  const isLoading = useSampleLoading(audioContext, setCSamples);

  const lastPlayedNote = useRef(null);
  const audioContextRef = useRef(null);
  const cSamplesRef = useRef(null);
  const compressorRef = useRef(null);

  useEffect(() => {
    audioContextRef.current = audioContext;
    cSamplesRef.current = cSamples;
  }, [audioContext, cSamples]);

  useEffect(() => {
    if (navigator.requestMIDIAccess) {
      navigator.requestMIDIAccess()
        .then(onMIDISuccess)
        .catch(onMIDIFailure);
    } else {
      console.log('WebMIDI is not supported in this browser.');
    }
  // eslint-disable-next-line
  }, [audioContext]);

  // Add this to your useEffect that sets up the audio context
  useEffect(() => {
    if (audioContext) {
      const comp = audioContext.createDynamicsCompressor();
      comp.threshold.setValueAtTime(-24, audioContext.currentTime);
      comp.knee.setValueAtTime(30, audioContext.currentTime);
      comp.ratio.setValueAtTime(12, audioContext.currentTime);
      comp.attack.setValueAtTime(0.003, audioContext.currentTime);
      comp.release.setValueAtTime(0.25, audioContext.currentTime);
      comp.connect(audioContext.destination);
      compressorRef.current = comp;
    }
  }, [audioContext]);

  // Control "pressed" state
  const pressNote = useCallback((note) => {
    setPressedNotes(prev => {
      let newPressed = prev;
      if (!prev.includes(note)) {
        newPressed = [...prev, note];
      }
      return newPressed;
    });
  }, []);

  const unpressNote = useCallback((note) => {
    setPressedNotes(prev => {
      let newPressed = prev.filter(n => n !== note);
      return newPressed;
    });
  }, []);

  const playNote = useCallback((note, velocity = 127) => {
    let currAudioContext = audioContextRef.current;
    let currCSamples = cSamplesRef.current;
    if (!currAudioContext || !currCSamples) return;

    pressNote(note);

    // If the note is already playing, stop it first
    if (playingNotes[note]) {
      const { source, gainNode } = playingNotes[note];
      source.stop();
      gainNode.disconnect();
    }
  
    // Find the closest C sample
    const noteOctave = parseInt(note.match(/\d+/)[0]);
    
    let closestC = `C${noteOctave}`;
    let minDistance = Math.abs(getSemitoneDistance(note, closestC));
  
    // Check if the C in the octave above is closer
    const upperC = `C${noteOctave + 1}`;
    if (currCSamples[upperC]) {
      const upperDistance = Math.abs(getSemitoneDistance(note, upperC));
      if (upperDistance < minDistance) {
        closestC = upperC;
        minDistance = upperDistance;
      }
    }
  
    // Check if the C in the octave below is closer
    const lowerC = `C${noteOctave - 1}`;
    if (currCSamples[lowerC]) {
      const lowerDistance = Math.abs(getSemitoneDistance(note, lowerC));
      if (lowerDistance < minDistance) {
        closestC = lowerC;
      }
    }
  
    const sampleBuffer = currCSamples[closestC];
  
    if (!sampleBuffer) {
      console.error(`No sample found for ${closestC}`);
      return;
    }
  
    const source = currAudioContext.createBufferSource();
    source.buffer = sampleBuffer;
  
    // Calculate the playback rate
    const baseFrequency = noteFrequencies[closestC];
    const targetFrequency = noteFrequencies[note];
    const playbackRate = targetFrequency / baseFrequency;
  
    source.playbackRate.setValueAtTime(playbackRate, currAudioContext.currentTime);
  
    const gainNode = currAudioContext.createGain();

    // Use velocity to control volume (normalize to 0-1 range)
    const normalizedVelocity = velocity / 127;
    gainNode.gain.setValueAtTime(normalizedVelocity, currAudioContext.currentTime);
  
    source.connect(gainNode);
    if (compressorRef.current) {
      gainNode.connect(compressorRef.current);
    } else {
      gainNode.connect(audioContextRef.current.destination);
    }
  
    source.start();
  
    setPlayingNotes(prev => {
      const newActiveNotes = { ...prev, [note]: { source, gainNode } };
      return newActiveNotes;
    });

    setTimeout(() => {
      setPlayingNotes(prev => {
        const newPlayingNotes = { ...prev   };
        delete newPlayingNotes[note];
        return newPlayingNotes;
      });
    }, 1500); 

  }, [playingNotes, pressNote]);

  // Update the stopNote function as well
  const stopNote = useCallback((note) => {
    if (playingNotes[note]) {
      const { source, gainNode } = playingNotes[note];
      gainNode.gain.setValueAtTime(gainNode.gain.value, audioContext.currentTime);
      gainNode.gain.exponentialRampToValueAtTime(0.00001, audioContext.currentTime + 0.03);
      setTimeout(() => {
        source.stop();
      }, 30);

      setPlayingNotes(prev => {
        const newActiveNotes = { ...prev };
        delete newActiveNotes[note];
        return newActiveNotes;
      });
    }
  }, [audioContext, playingNotes]);;

  useEffect(() => {
    detectChord(presssedNotes);
  }, [presssedNotes])

  const detectChord = (notes) => {
    if (notes.length < 3) {
      setCurrentChord('None');
      return;
    }
  
    const noteValues = notes.map(noteName => {
      return Object.keys(noteFrequencies).indexOf(noteName) % 12;
    }).sort((a, b) => a - b);  // Sort the note values
  
    const findRoot = (values) => {
      // Try each note as the potential root
      for (let i = 0; i < values.length; i++) {
        const potentialRoot = values[i];
        const intervals = values.map(v => (v - potentialRoot + 12) % 12).sort((a, b) => a - b);
        const intervalString = intervals.join(',');
        if (chords[intervalString]) {
          return { root: potentialRoot, intervals: intervals };
        }
      }
      return null;
    };
  
    const chords = {
      // Triads
      '0,4,7': 'maj',
      '0,3,7': 'min',
      '0,3,6': 'dim',
      '0,4,8': 'aug',
      
      // Suspended chords
      '0,2,7': 'sus2',
      '0,5,7': 'sus4',
      
      // Seventh chords
      '0,4,7,10': '7',
      '0,4,7,11': 'maj7',
      '0,3,7,10': 'min7',
      '0,3,6,9': 'dim7',
      '0,3,6,10': 'm7b5',
      
      // Sixth chords
      '0,4,7,9': '6',
      '0,3,7,9': 'm6',
      
      // Extended chords
      '0,2,4,7,10': '9',
      '0,2,4,7,11': 'maj9',
      '0,2,3,7,10': 'min9',
      '0,2,4,5,7,10': '11',
      '0,2,4,5,7,11': 'maj11',
      '0,2,3,5,7,10': 'min11',
      '0,2,4,5,7,9,10': '13',
      '0,2,4,5,7,9,11': 'maj13',
      '0,2,3,5,7,9,10': 'min13',
      
      // Add chords
      '0,2,4,7': 'add9',
      '0,2,3,7': 'madd9',
      '0,4,5,7': 'add11',
      
      // Altered chords
      '0,4,6,10': '7b5',
      '0,4,8,10': '7#5',
      '0,3,7,11': 'mMaj7',
    };
  
    const result = findRoot(noteValues);
  
    if (result) {
      const { root, intervals } = result;
      const rootNote = Object.keys(noteFrequencies)[root].slice(0, -1);
      const chordName = chords[intervals.join(',')];
      
      // Check for inversions
      const inversion = noteValues.indexOf(Math.min(...noteValues)) - noteValues.indexOf(root);
      const inversionStr = inversion > 0 ? `/${inversion + 1}` : '';
      
      setCurrentChord(`${rootNote}${chordName}${inversionStr}`);
    } else {
      // If no exact match, you could implement a closest match algorithm here
      setCurrentChord('Unknown');
    }
  };

  const handleMouseDown = useCallback((note) => {
    setIsDragging(true);
    playNote(note);
    lastPlayedNote.current = note;
  }, [playNote]);

  const handleMouseEnter = useCallback((note) => {
    if (isDragging && note !== lastPlayedNote.current) {
      if (lastPlayedNote.current) {
        stopNote(lastPlayedNote.current);
        unpressNote(lastPlayedNote.current);
      }
      playNote(note);
      lastPlayedNote.current = note;
    }
  }, [isDragging, playNote, stopNote, unpressNote]);

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
    if (lastPlayedNote.current) {
      // stopNote(lastPlayedNote.current);
      unpressNote(lastPlayedNote.current);
      lastPlayedNote.current = null;
    }
  }, [unpressNote]);

  const handleMouseLeave = useCallback((note) => {
    if (isDragging && lastPlayedNote.current) {
      stopNote(lastPlayedNote.current);
      unpressNote(note);
      lastPlayedNote.current = null;
    }
  }, [isDragging, stopNote, unpressNote]);

  const handleKeyDown = useCallback((event) => {
    if (event.repeat) return;
    const note = keyboardToNote[event.key.toLowerCase()];
    if (note) {
      playNote(note);
    }
  }, [playNote]);

  const handleKeyUp = useCallback((event) => {
    const note = keyboardToNote[event.key.toLowerCase()];
    unpressNote(note);
  }, [unpressNote]);

  useEffect(() => {
    // Add global mouse up listener
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseUp]);

  useEffect(() => {
    const handleGlobalMouseUp = () => {
      setIsDragging(false);
      if (lastPlayedNote.current) {
        stopNote(lastPlayedNote.current);
        lastPlayedNote.current = null;
      }
    };

    window.addEventListener('mouseup', handleGlobalMouseUp);
    return () => {
      window.removeEventListener('mouseup', handleGlobalMouseUp);
    };
  }, [stopNote]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp]);

  // Midi controls
  const noteFromMIDI = (midiNote) => {
    const notes = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];
    const octave = Math.floor(midiNote / 12) - 1;
    return `${notes[midiNote % 12]}${octave}`;
  };

  const onMIDISuccess = (midiAccess) => {
    console.log('MIDI ready!');
    for (let input of midiAccess.inputs.values()) {
      input.onmidimessage = getMIDIMessage;
    }
  };

  const onMIDIFailure = () => {
    console.log('Could not access your MIDI devices.');
  };

  const getMIDIMessage = (message) => {
    const command = message.data[0];
    const note = message.data[1];
    const velocity = (message.data.length > 2) ? message.data[2] : 0;
    const noteToPlay = noteFromMIDI(note);

    switch (command) {
      case 144: // noteOn
        if (velocity > 0) {
          playNote(noteToPlay, velocity);
        } else {
          stopNote(noteToPlay);
          unpressNote(noteToPlay);
        }
        break;
      case 128: // noteOff
        unpressNote(noteToPlay);
        break;
      default:
        break;
    }
  };



  const allKeys = Object.keys(noteFrequencies);
  const whiteKeys = allKeys.filter(note => !note.includes('#'));
  const totalWhiteKeys = whiteKeys.length;

  return (
    <div className="flex flex-col items-center justify-evenly min-h-screen bg-gray-100 p-4">
      <Helmet>
        <title>Piano Buddy | Online Piano Player</title>
        <meta name="description" content="Piano Buddy's online piano player allows you to practice piano anywhere for free." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div>
        <h1 className="text-3xl font-bold mt-4">Piano Buddy</h1>
        <p className="text-lg font-semibold">Play the piano with your computer keyboard or MIDI keyboard!</p>
      </div>
      {isLoading && (
        <div className="mt-4 text-xl font-semibold">Loading...</div>
      )}
      {!isLoading && (
        <div id="piano-container" className="flex flex-col items-center justify-center w-5/6">
          <div className="mt-4 mb-4 text-xl font-semibold">
            {`Chord: ${currentChord}`}
          </div>
          <div className="w-full overflow-hidden">
            <div className="relative" style={{ paddingBottom: '25%' }}>
              <div className="absolute top-0 left-0 right-0 bottom-0">
                {allKeys.map((note, index) => {
                  const isPlayingNote = playingNotes[note] !== undefined; 
                  const isPressedNote = presssedNotes.includes(note);
                  const isSharpNote = note.includes('#');
                  const isPlayableByKeyboard = Object.values(keyboardToNote).includes(note);
                  const whiteKeyIndex = whiteKeys.indexOf(note);
                  const keyWidth = 100 / totalWhiteKeys;
    
                  // Calculate the correct position for sharp notes
                  let leftPosition;
                  if (isSharpNote) {
                    const previousWhiteKeyIndex = whiteKeys.indexOf(allKeys[index - 1]);
                    leftPosition = `calc(${(previousWhiteKeyIndex + 1) * keyWidth}% - ${keyWidth * 0.3}%)`;
                  } else {
                    leftPosition = `${whiteKeyIndex * keyWidth}%`;
                  }
    
                  const nonActiveBg = isSharpNote ? 'bg-black' : 'bg-white';
                  const activeBg = isSharpNote ? 'bg-blue-400' : 'bg-blue-200';
                  const bgClass = isPressedNote ? activeBg : nonActiveBg;
                  const hoverBg = isPressedNote ? '' : (isSharpNote ? 'hover:bg-gray-800' : 'hover:bg-gray-100');
                  const noteLabelColor = isSharpNote ? 'text-white' : 'text-black';
                  const keyTypeClass = isSharpNote ? 'black-key' : 'white-key';
                  const glowClass = isPlayingNote ? 'glow' : '';
    
                  return (
                    <div
                      key={note}
                      style={{
                        position: 'absolute',
                        left: leftPosition,
                        width: isSharpNote ? `${keyWidth * 0.6}%` : `${keyWidth}%`,
                        height: isSharpNote ? '60%' : '100%',
                        zIndex: isSharpNote ? 1 : 0,
                      }}
                      className={`
                        key
                        ${keyTypeClass}
                        relative
                        border border-gray-300
                        flex items-end justify-center pb-2 
                        cursor-pointer 
                        transition-all duration-100 ease-in-out
                        ${bgClass} ${hoverBg} ${glowClass}
                      `}
                      onMouseDown={() => handleMouseDown(note)}
                      onMouseUp={() => handleMouseUp(note)}
                      onMouseEnter={() => handleMouseEnter(note)}
                      onMouseLeave={() => handleMouseLeave(note)}
                      onTouchStart={() => handleMouseDown(note)}
                      onTouchEnd={() => handleMouseUp(note)}
                    >
                      {(note.startsWith('C') && !note.includes('#')) && (
                        <div className="absolute bottom-2 text-xs">{note}</div>
                      )}
                      {isPlayableByKeyboard && (
                        <div className={`absolute top-0 left-0 right-0 text-center text-xs font-bold ${noteLabelColor}`}>
                          {Object.keys(keyboardToNote).find(key => keyboardToNote[key] === note)}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="mt-4 flex items-center">
            <Piano className="mr-2" />
            <p>Use the indicated keys on your computer keyboard to play!</p>
          </div>
        </div>
      )}
      </div>
  );
};

export default VirtualPiano;